@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.container-fluid {
    margin-top: 101px;
    color: #000 !important
}

.footer-card {
    color: #757575 !important;
    background-color: #fff !important;
    align-items: center !important;
    background: url('https://i.imgur.com/9djv5sH.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right calc(110px + (150 - 110) * ((100vw - 360px) / (1600 - 360))) bottom !important
}

.footer-section h2 {
    font-size: calc(25px + (45 - 25) * ((100vw - 360px) / (1600 - 360))) !important
}

.footer-section h5 {
    font-size: calc(20px + (28 - 20) * ((100vw - 360px) / (1600 - 360))) !important;
    margin-bottom: 2.8rem;
}

footer p{
    text-align: justify;
}

.footer-section button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    /* height: 2.95rem; */
    font-size: calc(12px + (13 - 12) * ((100vw - 360px) / (1600 - 320))) !important;
    padding-left: calc(20px + (28 - 20) * ((100vw - 360px) / (1600 - 360))) !important;
    padding-right: calc(20px + (28 - 20) * ((100vw - 360px) / (1600 - 360))) !important;
    padding-top: calc(10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))) !important;
    padding-bottom: calc(10px + (12 - 10) * ((100vw - 360px) / (1600 - 360))) !important
}

.footer-section button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

.footer-section li {
    margin-top: 10px;
    margin-bottom: 10px
}

.footer-section input {
    border: 2px solid #96d1b3 !important
}

.footer-section .footer-btn{
    border: 2px solid #498e8a;
    background: #498e8a;
    color: #fff;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.footer-section .footer-btn:hover{
    background: #96d1b3;
    border: 2px solid #96d1b3;
}

.footer-section .input-group-lg>.custom-select,
.footer-section .input-group-lg>.form-control:not(textarea) {
    height: calc(40px + (48 - 40) * ((100vw - 360px) / (1600 - 360))) !important
}

.footer-section input::-webkit-input-placeholder {
    padding-left: 5px !important;
    transform: translate3d(0, -4px, 0)
}

.footer-section input::placeholder {
    vertical-align: middle !important;
    font-size: calc(12px + (14 - 12) * ((100vw - 360px) / (1600 - 360))) !important
}

.footer-section input:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

.footer-section li:first-child {
    font-size: 21px !important;
    font-weight: bold;
    margin-bottom: calc(40px + (48 - 40) * ((100vw - 360px) / (1600 - 360))) !important;
    color: #757575 !important
}

.footer-section footer {
    color: #757575 !important
}

.footer-section footer ul .footer-link{
    text-decoration: none;
    color: #757575;
}

.footer-section footer ul .footer-link:hover{
    color: #96d1b3;
}


 .bottom-details{
    width: 100%;
    background: #498e8a;
    /* background: #1A5D1A; */
}

 .bottom-details .bottom_text{
    max-width: 1250px;
    margin: auto;
    padding: 20px 40px;
    display: flex;
    justify-content: space-between;
}

.bottom-details .bottom_text span,
.bottom-details .bottom_text a{
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    opacity: 1;
    text-decoration: none;
}

.bottom-details .bottom_text a:hover{
    opacity: 1;
    text-decoration: underline;
}

.bottom-details .bottom_text a{
    margin-right: 10px;
}
