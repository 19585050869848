/* header start */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-header-section{
    background: #fff;
    position: relative;
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.about-header-section:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(45deg, #ff0081, #6827b0); */
    background: #96d1b3;
    border-radius: 0 0 50% 50%/0 0 100% 100%;
    transform: scaleX(1.5);
}

.about-header-section .about-header-content{
    position: relative;
    z-index: 1;
    margin: 0 auto;
    max-width: 900px;
    text-align: center;
}

.about-header-section .about-header-content h2{
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 3em;
}

.about-header-section .about-header-content p{
    color: #fff;
    font-size: 1.2em;
}

/* header end */

/* about section start */

.about-section{
    width: 100%;
    height: 94vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-section .about-content img{
    height: auto;
    width: 600px;
    max-width: 100%;
}

.about-text{
    width: 550px;
    max-width: 100%;
    padding: 0 10px;
}

.about-content{
    width: 1280px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.about-text h1{
    color: #498e8a;
    font-size: 85px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.about-text h5{
    color: black;
    font-size: 25px;
    margin-bottom: 25px;
    text-transform: capitalize;
    letter-spacing: 2px;
}

.about-text p{
    color: black;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    margin-bottom: 45px;
    text-align: justify;
}

.about-section .about-text a{
    background-color: #498e8a;
    color: white;
    border: 2px solid transparent;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 17px;
    font-weight: bold;
}

.about-section .about-text a:hover{
    transform: scale(1.2);
    background-color: transparent;
    border: 2px solid #498e8a;
    color: #498e8a;
    transition: .4s;
    cursor: pointer;
}

@media screen and (max-width: 1180px) {
    .about-section{
        width: 100%;
        height: auto;
        padding: 70px 0px;
    }
}

@media screen and (max-width: 650px) {
    .about-section .about-content img{
        margin-bottom: 35px;
    }
    .about-text h1{
        font-size: 60px;
        margin-bottom: 25px;
    }
}

/* about section end */